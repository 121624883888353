<script setup>
 import { storeToRefs } from 'pinia';
 import { useWindowStore } from '@/stores/window';
 const { innerWidth, breakpointMobile, breakpointDesktop } = storeToRefs(useWindowStore());
 import { makeHash } from '~/composables/helpers/makeHash';
 import { clone } from '@/composables/helpers/clone';
 import { testPassiveSupport } from '@/composables/helpers/client-only/passiveSupport';

 const props = defineProps({
  options: {
   type: Object,
   required: false,
   default: () => ({}),
  },
 });

 const { options } = props;

 const emit = defineEmits(['videoPlaying']);

 const queryString = `&start=1&controls=0&disablekb=1&autoplay=1&iv_load_policy=3&mute=1&showinfo=0&rel=0&enablejsapi=1&loop=1&playsinline=1&modestbranding=1&vq=hd1080&playlist=`;
 const youtubeURL = computed(() => {
  if (!options?.embed) return '';

  const xmlString = options?.embed;
  const regex = 'src\s*=\s*"(.+?)"';
  let found = xmlString.match(regex);
  if (!found?.length) return '';

  const cloned = clone(found);
  const afterEmbed = cloned[1].split('/embed/')[1];
  const youtubeID = afterEmbed.split('?si')[0];
  imageUrl.value = `https://img.youtube.com/vi/${youtubeID}/0.jpg`;

  return `${found[1]}${queryString}${youtubeID}`;
 });

 const imageUrl = computed(() => {
  const split = youtubeURL.value.split('/');
  if (!split?.length) return false;
  const id = split[split.length - 1]?.split('?')[0];

  return `https://i3.ytimg.com/vi/${id}/maxresdefault.jpg`;
 });

 const video = ref(null);

 const playMessage = `{"event":"command","func":"playVideo","args":""}`;
 function playVideo() {
  if (process.client) {
   if (!video.value?.contentWindow) return;
   video.value?.contentWindow?.postMessage(playMessage, '*');
   emit('videoPlaying');
  }
 }
 const stopMessage = `{"event":"command","func":"stopVideo","args":""}`;
 function stopVideo() {
  emit('videoStopped');
  if (process.client) {
   if (!video.value?.contentWindow) return;
   video.value?.contentWindow?.postMessage(stopMessage, '*');
  }
 }

 const makeID = computed(() => {
  const frozenProps = JSON.stringify(props);
  return 'iframe-' + makeHash(frozenProps);
 });

 const openModal = ref(false);
 function toggleModal(bool = false) {
  openModal.value = bool;
 }

 const stopLoad = ref(false);
 const loadVideo = () => {
  if (video.value && !stopLoad.value) {
   const src = video.value.getAttribute('data-src');
   if (src) {
    video.value.src = src;
    stopLoad.value = true;
    playVideo();
    window.removeEventListener('mousemove', loadVideo);
    window.removeEventListener('touchstart', loadVideo);
    window.removeEventListener('scroll', loadVideo);
   }
  }
 };

 const videoOptions = {
  addPlayFullVideoButton: true,
  embed: options?.embed,
 };

 const videoPlaying = ref(false);

 const handlePlay = () => {
  if (innerWidth.value < breakpointMobile.value) return;
  videoPlaying.value = true;
 };

 const handleStop = () => {
  videoPlaying.value = false;
 };

 // Lifecycle
 onMounted(() => {
  if (innerWidth.value >= breakpointMobile.value) {
   if (video.value && makeID.value?.length) {
    playVideo();
   }
  } else {
   stopVideo();
   openModal.value = false;
  }

  const supportsPassive = testPassiveSupport();
  const options = supportsPassive ? { passive: true } : false;
  window.addEventListener('mousemove', loadVideo, options);
  window.addEventListener('touchstart', loadVideo, options);
  window.addEventListener('scroll', loadVideo, options);
 });

 onBeforeUnmount(() => {
  window.removeEventListener('mousemove', loadVideo);
  window.removeEventListener('scroll', loadVideo);
 });

 watch(innerWidth, () => {
  if (innerWidth.value >= breakpointMobile.value) {
   playVideo();
  } else {
   stopVideo();
   openModal.value = false;
  }
 });
</script>

<template>
 <div class="youtube-container-clip" tabindex="-1">
  <div class="youtube-container" tabindex="-1">
   <iframe
    v-if="youtubeURL?.length && makeID?.length"
    :id="makeID"
    loading="eager"
    tabindex="-1"
    ref="video"
    :data-src="youtubeURL"
    height="100%"
    width="100%"
    allow="autoplay; controls; loop; mute; playsinline; playlist; enablejsapi; encrypted-media;"
    referrerpolicy="strict-origin-when-cross-origin"
   />
   <img v-if="imageUrl" :src="imageUrl" :lazy="false" :height="1080" :width="1920" />
  </div>
  <ProjectButton
   v-if="options?.addPlayFullVideoButton"
   @click.prevent="toggleModal(true)"
   class="play-button"
   version="white"
   icon="play"
   label="Play Full Video"
   title="Play Full Video"
  />

  <ProjectModal @close="toggleModal(false)" :opened="openModal">
   <div v-if="options?.embed" v-html="options?.embed" class="full-video-wrapper"></div>
  </ProjectModal>
 </div>
</template>

<style lang="scss">
 .youtube-container-clip {
  z-index: 0;
  backface-visibility: hidden;
  clip: rect(auto, auto, auto, auto);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  user-select: none;
  pointer-events: none;

  .play-button {
   z-index: 1;
   pointer-events: all;
   position: absolute;
   right: 40px;
   bottom: 40px;
   display: flex;
   align-items: center;
   justify-content: center;

   .icon-wrapper {
    margin-right: 10px;
   }
  }

  .youtube-container {
   position: absolute;
   aspect-ratio: 16/9;
   overflow: clip;
   pointer-events: none;
   user-select: none;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);

   min-width: 100%;
   min-height: 100%;
   object-fit: cover;

   iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    aspect-ratio: 1 / 2;
    width: 100%;
    height: unset;
    z-index: 1;
   }
   img {
    @include absolute-center;

    object-fit: cover;
    z-index: 0;
   }
  }
 }
</style>
