export const testPassiveSupport = () => {
 let supportsPassive = false;
 try {
  const opts = Object.defineProperty({}, 'passive', {
   get() {
    supportsPassive = true;
   },
  });
  window.addEventListener('test', null, opts);
  window.removeEventListener('test', null, opts);
 } catch (e) {}
 return supportsPassive;
};
